<template>
  <div>
    <h2 class="content-block">Purchases - ประวัติการซื้อ</h2>
    <div class="content-block">

      <div class="dx-card responsive-paddings" v-if="purchases">
        <DxDataGrid ref="dataGridRef" :data-source="purchases" :allow-column-reordering="true"
          :allow-column-resizing="true" :column-hiding-enabled="true" :column-auto-width="true" :show-borders="true"
          :focused-row-enabled="true" :word-wrap-enabled="true" key-expr="docID" @exporting="onExporting">
          <DxColumn data-field="docDate" caption="Doc date" :width="145" data-type="date" format="dd/MM/yyyy"
            sort-order="asc" />

          <DxColumn data-field="productCode" caption="Product code" :width="180" :group-index="0" />
          <DxColumn data-field="productName" caption="Product name" />
          <DxColumn data-field="quantity" caption="Quantity" :width="150" data-type="number" format="#,##0" />
          <DxColumn data-field="amount" caption="Amount" :width="150" data-type="number" format="#,##0.00" />
          <DxColumn data-field="remark" caption="Remark" :visible="false" />

          <DxSelection mode="single" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <!-- <DxFilterRow :visible="true" /> -->
          <!-- <DxHeaderFilter :visible="true" :allow-search="true" /> -->
          <DxHeaderFilter :visible="true" :search="{ enabled: true }" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />
          <DxColumnChooser :enabled="true" mode="dragAndDrop" /><!-- dragAndDrop or "select" -->
          <DxFilterPanel :visible="true" />
          <DxSorting mode="multiple" />

          <DxStateStoring :enabled="true" type="localStorage" storage-key="storagePurchases" />

          <DxExport :enabled="true" :allow-export-selected-data="false" />

          <DxPaging :page-size="10" />
          <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode"
            :show-page-size-selector="showPageSizeSelector" :show-info="showInfo"
            :show-navigation-buttons="showNavButtons" />

          <DxSummary>
            <DxTotalItem column="docDate" summary-type="count" />
            <DxTotalItem column="quantity" summary-type="sum" value-format="#,##0" display-format="Total: {0}" />
            <DxTotalItem column="amount" summary-type="sum" value-format="#,##0.00" display-format="Total: {0}" />
            <DxGroupItem summary-type="count" value-format="#,##0" />
            <DxGroupItem :show-in-group-footer="true" column="quantity" summary-type="sum" value-format="#,##0"
              display-format="{0}" />
            <DxGroupItem :show-in-group-footer="true" column="amount" summary-type="sum" value-format="#,##0.00"
              display-format="{0}" />
          </DxSummary>

          <DxToolbar>
            <DxItem template="customTemplate" />

            <DxItem name="columnChooserButton" />
            <DxItem name="exportButton" />
            <DxItem name="searchPanel" />
            <DxItem name="groupPanel" />
          </DxToolbar>

          <template #customTemplate>
            <DxButton hint="Reset layout" icon="revert" styling-mode="text" @click="onStateResetClick" />
            <DxButton hint="Refresh data" icon="refresh" styling-mode="text" @click="fetchData" />
          </template>

          <DxLoadPanel :enabled="true" />
        </DxDataGrid>
      </div>
      <div class="center-container" v-else>
        <DxLoadIndicator />
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import notify from 'devextreme/ui/notify';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import {
  DxDataGrid, DxColumn, DxColumnChooser,
  DxSearchPanel, DxGroupPanel, DxSelection, DxSummary, DxTotalItem,
  DxGroupItem, DxGrouping,
  DxLoadPanel, DxHeaderFilter, DxFilterPanel, DxSorting, DxPaging, DxPager, DxStateStoring, DxToolbar, DxItem, DxExport
} from 'devextreme-vue/data-grid'; //DxToolbar,  DxItem
import { DxButton } from 'devextreme-vue/button';

// import { jsPDF } from 'jspdf';
// import { exportDataGrid } from 'devextreme/pdf_exporter';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';

import axios from 'axios';
import { useAuthStore } from "../stores/useAuthStore";
import { ENDPOINTS } from "../utils/constants";
import checkTokenExpired from "../utils/checkTokenExpired";

import { useRouter } from 'vue-router';

const purchases = ref(null);

// const expanded = ref(true);
const displayMode = ref('full');
const pageSizes = ref([5, 10, 'all']);
const showPageSizeSelector = ref(true);
const showInfo = ref(true);
const showNavButtons = ref(true);

const router = useRouter();

const dataGridRef = ref(null);
const onStateResetClick = () => {
  dataGridRef.value.instance.state(null)
};

const fetchData = async () => {
  try {
    const token = localStorage.getItem("token");
    const auth = useAuthStore();

    let custId = "";
    if (auth.isLoggedIn && auth.user !== null) {
      custId = auth.user.custID;
    }

    const response = await axios.get(ENDPOINTS.GET_PURCHASES_CUSTID.replace("{custId}", custId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      purchases.value = response.data;
    } else {
      // console.error("Failed to fetch purchases data.");
      notify("Failed to fetch purchases data.", "error");
    }
  } catch (error) {
    // console.error(error);
    notify("Failed to fetch purchases data.", "error");
  }
};

// const onExportingPDF = (e) => {
//   const doc = new jsPDF();
//   exportDataGrid({
//     jsPDFDocument: doc,
//     component: e.component,
//     indent: 5,
//   }).then(() => {
//     doc.save('Purchases.pdf');
//   });
// };

const onExporting = async (e) => {
  const workbook = new Workbook();
  const sheetname = 'Purchases';
  const worksheet = workbook.addWorksheet(sheetname);

  await exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetname}.xlsx`);
  });

  e.cancel = true;
};

// onMounted(async () => {
//   try {
//     const token = localStorage.getItem("token");
//     const auth = useAuthStore();

//     let custId = "";
//     if (auth.isLoggedIn && auth.user !== null) {
//       custId = auth.user.custID;
//     }

//     const response = await axios.get(ENDPOINTS.GET_PURCHASES_CUSTID.replace("{custId}", custId), {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.status === 200) {
//       purchases.value = response.data;
//     } else {
//       // console.error("Failed to fetch purchases data.");
//       notify("Failed to fetch purchases data.", "error");
//     }
//   } catch (error) {
//     // console.error(error);
//     notify("Failed to fetch purchases data.", "error");
//   }
// });

// onMounted(fetchData);

onMounted(() => {
  const isExpired = checkTokenExpired();

  if (isExpired.value) {
    const auth = useAuthStore();
    auth.logout();

    // หาก Token หมดอายุ ให้ทำการ redirect ไปยังหน้า login
    notify("Your token has expired. You will be redirected to the login page...", "warning");
    router.push({ name: 'login-form' });
  } else {
    // หาก Token ยังไม่หมดอายุ ทำการดึงข้อมูล
    fetchData();
  }
});

</script>


<style lang="scss" scoped></style>
