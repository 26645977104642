// import "devextreme/dist/css/dx.common.css";
// import "./themes/generated/theme.base.css";
// import "./themes/generated/theme.additional.css";

import './themes/generated/variables.css';

import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import themes from "devextreme/ui/themes";
import axios from "axios";
import { useAuthStore } from "./stores/useAuthStore";

import App from "./App";
import appInfo from "./app-info";

import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license'; 
 
config({ licenseKey });  

// const pinia = createPinia();

themes.initialized(() => {
  // ตั้งค่า axios และการจัดการกับการตอบสนองที่มีสถานะ 401
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Call the logout function to log out the user
        const auth = useAuthStore();
        auth.logout();

        return Promise.reject(new Error("Invalid username or password"));
      }
      return Promise.reject(error);
    }
  );
  
  const app = createApp(App).use(createPinia());
  app.use(router);
  app.config.globalProperties.$appInfo = appInfo;

  app.mount("#app");
});
