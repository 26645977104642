export const BASE_URL = "https://custserv.compatbizcloud.com";
// export const BASE_URL = "https://localhost:7082";

export const ENDPOINTS = {
  GET_TOKEN: `${BASE_URL}/Account/GenerateToken`,
  GET_PRODUCTS: `${BASE_URL}/Master/GetProducts`,
  GET_CUSTOMERS: `${BASE_URL}/Master/GetCustomers`,
  GET_CUSTOMER_CUSTID: `${BASE_URL}/Account/GetCustomerById/{custId}`,
  GET_CUSTOMER_EMAIL: `${BASE_URL}/Account/GetCustomerByEmail/{custEmail}`,
  GET_PURCHASES_CUSTID: `${BASE_URL}/License/GetSalesByCustomer/{custId}`,
  GET_ACTIVATES_CUSTID: `${BASE_URL}/License/GetActivateLicensesByCustomer/{custId}`,
  GET_ACTIVATES_CUSTID_PROID: `${BASE_URL}/License/GetActivateLicenses/{custId}/{productId}`,
  GET_MAS_CUSTID: `${BASE_URL}/License/GetMAsByCustomer/{custId}`,
  GET_SERVICES_CUSTID: `${BASE_URL}/License/GetServicesByCustomer/{custId}`,
  GET_SALEACTIVATION_SUMMARY: `${BASE_URL}/License/GetSalesActivationsSummary/{custId}`,
  GET_DOCURL_DOCID: `${BASE_URL}/License/GetDocumentURL/{docId}`,
  GET_DOCFILE_DOCID: `${BASE_URL}/License/GetDocumentFile/{docId}`,
  GET_DOCBASE64_DOCID: `${BASE_URL}/License/GetDocumentBase64/{docId}`,
};

export const DEFAULT_AVATAR_URL = "../assets/user.png";
