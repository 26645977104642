export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Purchases and Services",
    icon: "folder",
    items: [
      {
        text: 'Purchases',
        path: '/purchases-history',
      }, 
      {
        text: 'Activate License',
        path: '/activate-license',
      }, 
      {
        text: 'Maintenance Agreements',
        path: '/maintenance-agreements',
      }, 
      {
        text: 'Services History',
        path: '/services-history',
      }      
    ]
  }, 
  
  {
    text: 'User Profile',
    path: '/user-profile',
    icon: 'user'
  }

  ];
