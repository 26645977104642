import { ref } from "vue";

function checkTokenExpired() {
  const isExpired = ref(false);

  const tokenString = localStorage.getItem("token");
  if (tokenString) {
    const token = JSON.parse(atob(tokenString.split(".")[1]));
    const expirationTime = token.exp * 1000;
    const currentTime = Date.now();
    isExpired.value = expirationTime <= currentTime;
  } else {
    isExpired.value = true;
  }

  return isExpired;
}

export default checkTokenExpired;
