import { ref, watch } from "vue";
import { currentTheme, refreshTheme } from "devextreme/viz/themes";

const theme = ref(
  window.localStorage.getItem("themePreference") || "saas.light"
);

function isLightMode() {
  return theme.value.includes("light");
}

function applyTheme(selectedTheme) {
  const themeToApply = selectedTheme || theme.value;
  theme.value = themeToApply;
  window.localStorage.setItem("themePreference", themeToApply);
  currentTheme("fluent." + themeToApply);
  refreshTheme();
}

// Initialize theme on load
applyTheme();

// Watch for theme changes and apply them
watch(
  theme,
  (newValue) => {
    applyBaseTheme(newValue, "dx.fluent.");
    let accent = newValue.substring(newValue.indexOf(".") + 1);
    applySwatchVariables(accent);
    applySwatchTheme(accent, "theme.additional");
  },
  { immediate: true }
);

function applyBaseTheme(theme, themeMarker) {
  for (let index in document.styleSheets) {
    let styleSheet = document.styleSheets[index],
      href = styleSheet.href;
    if (href) {
      let themeMarkerPosition = href.indexOf(themeMarker);
      if (themeMarkerPosition >= 0) {
        let startPosition = themeMarkerPosition + themeMarker.length,
          endPosition = href.indexOf(".css"),
          fileNamePart = href.substring(startPosition, endPosition);
        if (fileNamePart === theme) {
          for (let i = 0; i < styleSheet.cssRules.length; i++) {
            let cssRule = styleSheet.cssRules.item(i);
            if (cssRule?.selectorText === ".dx-theme-accent-as-text-color") {
              document.documentElement.style.setProperty(
                "--base-accent",
                cssRule.style.color
              );
            }
          }
        }
        styleSheet.disabled = fileNamePart != theme;
      }
    }
  }
}

function applySwatchVariables(accent) {
  if (accent === "light") {
    document.documentElement.style.setProperty(
      "--base-border-color",
      "#F3F3F3"
    );
    document.documentElement.style.setProperty(
      "--base-bg",
      "rgba(0, 0, 0, 0.16)"
    );
    document.documentElement.style.setProperty(
      "--icon-color",
      "rgba(0, 0, 0, 0.54)"
    );
  } else {
    document.documentElement.style.setProperty(
      "--base-border-color",
      "#464650"
    );
    document.documentElement.style.setProperty(
      "--base-bg",
      "rgba(255, 255, 255, 0.10)"
    );
    document.documentElement.style.setProperty(
      "--icon-color",
      "rgba(255, 255, 255, 0.87)"
    );
  }
}

function applySwatchTheme(accent, themeMarker) {
  for (let index in document.styleSheets) {
    let styleSheet = document.styleSheets[index],
      href = styleSheet.href;
    if (href) {
      let themeMarkerPosition = href.indexOf(themeMarker);

      if (themeMarkerPosition >= 0) {
        let startPosition = themeMarkerPosition + themeMarker.length,
          endPosition = href.indexOf(".css"),
          fileNamePart = href.substring(startPosition, endPosition);
        styleSheet.disabled = !(
          accent === fileNamePart.substring(fileNamePart.indexOf(".") + 1)
        );
      }
    }
  }
}

export default {
  theme,
  isLightMode,
  applyTheme,
};
