<template>
  <div>
    <h2 class="content-block">Services History - ประวัติการให้บริการ</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings" v-if="services">
        <DxDataGrid ref="dataGridRef" :data-source="services" :allow-column-reordering="true"
          :allow-column-resizing="true" :column-hiding-enabled="true" :column-auto-width="true" :show-borders="true"
          :showCommandColumn="true" :focused-row-enabled="true" :word-wrap-enabled="true" key-expr="docID"
          @exporting="onExporting">
          <!-- @focused-row-changed="onFocusedRowChanged" -->
          <!-- @cell-prepared="handleCellPrepared" -->

          <DxColumn data-field="docDate" caption="Doc date" :width="145" data-type="date" format="dd/MM/yyyy"
            sort-order="asc" />

          <DxColumn data-field="serviceType" caption="Service type" :width="220">
            <DxLookup :data-source="serviceType" value-expr="serviceID" display-expr="serviceName" />
          </DxColumn>
          <DxColumn data-field="productCode" caption="Product code" :width="180" :group-index="0" />
          <DxColumn data-field="productName" caption="Product name" />
          <DxColumn data-field="remark" caption="Remark" />
          <DxColumn data-field="docID" caption="Doc file" :width="82" :allow-reordering="false" :allow-sorting="false"
            :allow-filtering="false" cell-template="address-cell" />

          <DxSelection mode="single" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <!-- <DxFilterRow :visible="true" /> -->
          <!-- <DxHeaderFilter :visible="true" :allow-search="true" /> -->
          <DxHeaderFilter :visible="true" :search="{ enabled: true }" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />
          <DxColumnChooser :enabled="true" mode="dragAndDrop" /><!-- dragAndDrop or "select" -->
          <DxFilterPanel :visible="true" />
          <DxSorting mode="multiple" />

          <DxStateStoring :enabled="true" type="localStorage" storage-key="storageServices" />

          <DxExport :enabled="true" :allow-export-selected-data="false" />

          <DxPaging :page-size="10" />
          <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode"
            :show-page-size-selector="showPageSizeSelector" :show-info="showInfo"
            :show-navigation-buttons="showNavButtons" />

          <DxSummary>
            <DxTotalItem column="docDate" summary-type="count" />
            <DxGroupItem summary-type="count" value-format="#,##0" />
          </DxSummary>

          <DxToolbar>
            <DxItem template="customTemplate" />

            <DxItem name="columnChooserButton" />
            <DxItem name="exportButton" />
            <DxItem name="searchPanel" />
            <DxItem name="groupPanel" />
          </DxToolbar>

          <template #customTemplate>
            <DxButton hint="Reset layout" icon="revert" styling-mode="text" @click="onStateResetClick" />
            <DxButton hint="Refresh data" icon="refresh" styling-mode="text" @click="fetchData" />
          </template>

          <template #address-cell="{ data }">
            <div style="display: flex; justify-content: center; ">
              <DxButton v-if="showViewDocument(data.data.serviceType)" type="normal" icon="attach"
                hint="View Documentation" @click="ViewDocumentation(data.data)" />
            </div>
          </template>

          <DxLoadPanel :enabled="true" />
        </DxDataGrid>
      </div>
      <div class="center-container" v-else>
        <DxLoadIndicator />
      </div>
    </div>
  </div>

  <DxPopup v-model:visible="isPopupVisible" content-template="popup-content" :hide-on-outside-click="true"
    :show-title="true" title="Document file" :height="700" :width="800" :resize-enabled="true" :drag-enabled="true"
    position="center" :show-close-button="true">
    <template #popup-content>
      <DxScrollView height="100%" width="100%">
        <DocumentFiles :docId="docId" />
      </DxScrollView>
    </template>
  </DxPopup>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import notify from 'devextreme/ui/notify';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import { DxPopup } from 'devextreme-vue/popup';
import { DxScrollView } from "devextreme-vue/scroll-view";
import {
  DxDataGrid, DxColumn, DxColumnChooser,
  DxSearchPanel, DxGroupPanel, DxSelection, DxSummary, DxTotalItem,
  DxGroupItem, DxGrouping,
  DxLoadPanel, DxHeaderFilter, DxFilterPanel, DxSorting, DxPaging, DxPager, DxStateStoring, DxToolbar, DxItem, DxLookup, DxExport
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';

import axios from 'axios';
import { useAuthStore } from "../stores/useAuthStore";
import { ENDPOINTS } from "../utils/constants";
import checkTokenExpired from "../utils/checkTokenExpired";

import { useRouter } from 'vue-router';
import DocumentFiles from '@/components/document-files.vue';

const services = ref(null);
const isPopupVisible = ref(false);
const docId = ref("");


// const expanded = ref(true);
const displayMode = ref('full');
const pageSizes = ref([5, 10, 'all']);
const showPageSizeSelector = ref(true);
const showInfo = ref(true);
const showNavButtons = ref(true);

const router = useRouter();

const dataGridRef = ref(null);
const onStateResetClick = () => {
  dataGridRef.value.instance.state(null)
};

const serviceType = [
  { serviceID: "Install", serviceName: "Install software" },
  { serviceID: "Training", serviceName: "User Training" },
  { serviceID: "ChangeEmailForm", serviceName: "Email Change Form" },
  { serviceID: "RegisForm", serviceName: "Reg Info Request Form" },
  { serviceID: "ChangePCForm", serviceName: "Computer Replacement Form" },
  { serviceID: "ChangePC", serviceName: "Install/Move Computer" },
  { serviceID: "RemoteAccess", serviceName: "Remote Access" }
];


const ViewDocumentation = (rowData) => {
  docId.value = rowData.docID;

  isPopupVisible.value = true;
}

const showViewDocument = (serviceType) => {
  const serviceTypes = ['ChangeEmailForm', 'RegisForm', 'ChangePCForm'];
  return serviceTypes.includes(serviceType);
};

// const onFocusedRowChanged = (e) => {
//   const data = e.row && e.row.data;
//   this.taskSubject = data && data.Task_Subject;
//   this.taskDetails = data && data.Task_Description;
//   this.taskStatus = data && data.Task_Status;
//   this.taskProgress = data && data.Task_Completion ? `${data.Task_Completion}%` : '';
//   this.focusedRowKey = e.component.option('focusedRowKey');
// }


// const handleCellPrepared = (e) => {
//   if (e.rowType === "data" && e.column.dataField === "productName") {
//     const link = e.data.productName; // Replace "yourDataField" with the actual data field name
//     e.cellElement.innerHTML = `<a href="${link}">${link}</a>`;
//     // e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
//     // // Tracks the `Amount` data field
//     // e.watch(function () {
//     //   return e.data.Amount;
//     // }, function () {
//     //   e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
//     // })
//   }
// }

const fetchData = async () => {
  try {
    const token = localStorage.getItem("token");
    const auth = useAuthStore();

    let custId = "";
    if (auth.isLoggedIn && auth.user !== null) {
      custId = auth.user.custID;
    }

    const response = await axios.get(ENDPOINTS.GET_SERVICES_CUSTID.replace("{custId}", custId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      services.value = response.data;
    } else {
      // console.error("Failed to fetch services data.");
      notify("Failed to fetch services data.", "error");
    }
  } catch (error) {
    // console.error(error);
    notify("Failed to fetch services data.", "error");
  }
};

const onExporting = async (e) => {
  const workbook = new Workbook();
  const sheetname = 'ServicesHistory';
  const worksheet = workbook.addWorksheet(sheetname);

  await exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetname}.xlsx`);
  });

  e.cancel = true;
};

// onMounted(fetchData);

onMounted(() => {
  const isExpired = checkTokenExpired();

  if (isExpired.value) {
    const auth = useAuthStore();
    auth.logout();

    // หาก Token หมดอายุ ให้ทำการ redirect ไปยังหน้า login
    notify("Your token has expired. You will be redirected to the login page...", "warning");
    router.push({ name: 'login-form' });
  } else {
    // หาก Token ยังไม่หมดอายุ ทำการดึงข้อมูล
    fetchData();
  }
});

watch(() => docId, () => {
  isPopupVisible.value = true;
});
</script>


<style lang="scss" scoped>
/* .button-container {
  display: flex;
  justify-content: center;
} */

/* .dx-button.dx-button {
  width: auto;
  height: auto;
} */
</style>
