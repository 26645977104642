<template>
  <DxButton class="theme-button" styling-mode="text" :icon="icon" @click="onClickButton" />
</template>

<script setup>
import { DxButton } from 'devextreme-vue/button';

import { computed } from 'vue';
import ThemeService from '../services/theme-service';

const icon = computed(() => ThemeService.isLightMode() ? 'moon' : 'sun');

function onClickButton() {
  ThemeService.applyTheme(ThemeService.isLightMode() ? "saas.dark" : "saas.light");
}
</script>