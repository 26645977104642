import PurchasesHistory from "./views/purchases-history.vue";
import UserProfile from "./views/user-profile.vue";
import ServicesHistory from "./views/services-history.vue";
import MaintenanceAgreements from "./views/maintenance-agreements.vue";
import ActivateLicense from "./views/activate-license.vue";

// import auth from "./auth";
import { useAuthStore } from "./stores/useAuthStore";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Home",
      },
      component: Home,
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In",
        description: "to Customer Support System",
      },
      component: loadView("login-form"),
    },
    // {
    //   path: "/reset-password",
    //   name: "reset-password",
    //   meta: {
    //     requiresAuth: false,
    //     layout: simpleLayout,
    //     title: "Reset Password",
    //     description:
    //       "Please enter the email address that you used to register, and we will send you a link to reset your password via Email.",
    //   },
    //   component: loadView("reset-password-form"),
    // },
    // {
    //   path: "/create-account",
    //   name: "create-account",
    //   meta: {
    //     requiresAuth: false,
    //     layout: simpleLayout,
    //     title: "Sign Up",
    //   },
    //   component: loadView("create-account-form"),
    // },
    // {
    //   path: "/change-password/:recoveryCode",
    //   name: "change-password",
    //   meta: {
    //     requiresAuth: false,
    //     layout: simpleLayout,
    //     title: "Change Password",
    //   },
    //   component: loadView("change-password-form"),
    // },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/recovery",
      redirect: "/home",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home",
    },
    {
      path: "/activate-license",
      name: "activate-license",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Activate License",
      },
      component: ActivateLicense,
    },
    {
      path: "/maintenance-agreements",
      name: "maintenance-agreements",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Maintenance Agreements",
      },
      component: MaintenanceAgreements,
    },
    {
      path: "/services-history",
      name: "services-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Services History",
      },
      component: ServicesHistory,
    },
    {
      path: "/user-profile",
      name: "user-profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "User Profile",
      },
      component: UserProfile,
    },
    {
      path: "/purchases-history",
      name: "purchases-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Purchases History",
      },
      component: PurchasesHistory,
    },
  ],
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  // document.title = to.meta?.title ?? "Customer Support System";

  const defaultTitle = "Customer Support System";
  document.title =
    to.meta?.title && to.meta.title !== defaultTitle
      ? `${to.meta.title} - ${defaultTitle}`
      : defaultTitle;

  const auth = useAuthStore();

  if (to.name === "login-form" && auth.isLoggedIn) {
    next({ name: "home" });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.isLoggedIn) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath },
      });
    } else {
      // const email = localStorage.getItem("email");
      // if (email !== null) {
      //   await authStore.getUser(email);
      // }
      next();
    }
  } else {
    next();
  }
});

export default router;
