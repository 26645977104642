<template>
  <div>
    <h2 class="content-block">User Profile - โปรไฟล์ผู้ใช้</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings" v-if="customer">
        <div class="dx-fieldset">
          <!-- <div class="dx-fieldset-header">Customer information</div> -->

          <div class="dx-field">
            <div class="dx-field-label">Code</div>
            <div class="dx-field-value-static">{{ customer.custCode ? customer.custCode : '-' }}</div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Name</div>
            <div class="dx-field-value-static">{{ customer.custName ? customer.custName : '-' }}</div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Address</div>
            <div class="dx-field-value-static">{{ customer.custAddress ? customer.custAddress : '-' }}</div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Contact person</div>
            <div class="dx-field-value-static">{{ customer.custContact ? customer.custContact : '-' }}</div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Phone number</div>
            <div class="dx-field-value-static">{{ customer.custTel ? customer.custTel : '-' }}</div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Email address</div>
            <div class="dx-field-value-static">{{ customer.custEmail ? customer.custEmail : '-' }}</div>
          </div>
        </div>
      </div>
      <div class="center-container" v-else>
        <DxLoadIndicator />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import notify from 'devextreme/ui/notify';
import DxLoadIndicator from 'devextreme-vue/load-indicator';

import axios from 'axios';
import { useAuthStore } from "../stores/useAuthStore";
import { ENDPOINTS } from "../utils/constants";
import checkTokenExpired from "../utils/checkTokenExpired";

import { useRouter } from 'vue-router';

const router = useRouter();
const customer = ref(null);

const fetchData = async () => {
  try {
    const token = localStorage.getItem("token");
    const auth = useAuthStore();

    let custId = "";
    if (auth.isLoggedIn && auth.user !== null) {
      custId = auth.user.custID;
    }

    const response = await axios.get(ENDPOINTS.GET_CUSTOMER_CUSTID.replace("{custId}", custId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      customer.value = response.data;
    } else {
      // console.error("Failed to fetch customer data.");
      notify("Failed to fetch customer data.", "error");
    }
  } catch (error) {
    // console.error(error);
    notify("Failed to fetch customer data.", "error");
  }
};

// onMounted(fetchData);

onMounted(() => {
  const isExpired = checkTokenExpired();

  if (isExpired.value) {
    const auth = useAuthStore();
    auth.logout();

    // หาก Token หมดอายุ ให้ทำการ redirect ไปยังหน้า login
    notify("Your token has expired. You will be redirected to the login page...", "warning");
    router.push({ name: 'login-form' });
  } else {
    // หาก Token ยังไม่หมดอายุ ทำการดึงข้อมูล
    fetchData();
  }
});

</script>

<style lang="scss" scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.dx-field {
  display: grid;
  justify-content: start;
  align-items: stretch;
  margin-bottom: 0px;
  height: auto;
}

.dx-field-label {
  width: auto;
  font-weight: bold;
}

.dx-field-value-static {
  width: auto;
  padding: 0px 0px 10px 0px;
}
</style>
