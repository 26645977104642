<template>
    <div>
        <div class="dx-card responsive-paddings" v-if="activateLicenses">
            <DxDataGrid :data-source="activateLicenses" :allow-column-reordering="true" :allow-column-resizing="true"
                :column-hiding-enabled="true" :column-auto-width="true" :show-borders="true" :focused-row-enabled="true"
                :word-wrap-enabled="true" key-expr="docDate">
                <DxColumn data-field="docDate" caption="Activation date" :width="180" data-type="date" format="dd/MM/yyyy HH:mm:ss"
                    sort-order="asc" />

                <DxColumn data-field="remark" caption="Remark" />
                <!-- <DxColumn data-field="docType" caption="Type" :width="120" /> -->
                <DxColumn data-field="productCode" caption="Product code" :width="180" :group-index="0" />
                <!-- <DxColumn data-field="productCode" caption="Product code" :width="180" :group-index="0" />
                <DxColumn data-field="productName" caption="Product name" /> -->
                <!-- <DxColumn data-field="appID" caption="appID" /> -->
                <!-- <DxColumn data-field="isCanceled" caption="isCanceled?" /> -->

                <DxSelection mode="single" />
                <DxGroupPanel :visible="true" />
                <DxGrouping :auto-expand-all="true" />
                <!-- <DxFilterRow :visible="true" /> -->
                <!-- <DxHeaderFilter :visible="true" :allow-search="true" /> -->
                <DxHeaderFilter :visible="true" :search="{ enabled: true }" />
                <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />
                <!-- <DxColumnChooser :enabled="true" mode="dragAndDrop" />dragAndDrop or "select" -->
                <DxFilterPanel :visible="true" />
                <DxSorting mode="multiple" />

                <!-- <DxStateStoring :enabled="true" type="localStorage" storage-key="storageActivates" /> -->

                <!-- <DxExport :enabled="true" :formats="['pdf']" :allow-export-selected-data="false" /> -->

                <DxPaging :page-size="5" />
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode"
                    :show-page-size-selector="showPageSizeSelector" :show-info="showInfo"
                    :show-navigation-buttons="showNavButtons" />

                <DxSummary>
                    <DxTotalItem column="docDate" summary-type="count" />
                    <DxGroupItem summary-type="count" value-format="#,##0" />
                </DxSummary>

                <!-- <DxToolbar>
                    <DxItem template="customTemplate" />

                    <DxItem name="columnChooserButton" />
                    <DxItem name="searchPanel" />
                    <DxItem name="groupPanel" />
                </DxToolbar>

                <template #customTemplate>
                    <DxButton hint="Reset layout" icon="revert" @click="onStateResetClick" />
                    <DxButton hint="Refresh data" icon="refresh" @click="fetchData" />
                </template> -->

                <DxLoadPanel :enabled="true" />
            </DxDataGrid>
        </div>

        <div class="center-container" v-else>
            <DxLoadIndicator />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';
import notify from 'devextreme/ui/notify';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import {
    DxDataGrid, DxColumn,
    DxSelection, DxSummary, DxTotalItem,
    DxGroupItem,
    DxLoadPanel, DxPaging, DxPager, DxHeaderFilter, DxFilterPanel, DxSearchPanel, DxGroupPanel, DxGrouping, DxSorting
} from 'devextreme-vue/data-grid';

import axios from 'axios';

import { useAuthStore } from "../stores/useAuthStore";
import { ENDPOINTS } from "../utils/constants";
import checkTokenExpired from "../utils/checkTokenExpired";

import { useRouter } from 'vue-router';

const activateLicenses = ref(null);

const props = defineProps({
    productId: {
        type: String,
        required: true,
    }
});
// const expanded = ref(true);
const displayMode = ref('full');
const pageSizes = ref([5, 10, 'all']);
const showPageSizeSelector = ref(true);
const showInfo = ref(true);
const showNavButtons = ref(true);

const router = useRouter();

// const dataGridRef = ref(null);
// const onStateResetClick = () => {
//     dataGridRef.value.instance.state(null)
// };

const fetchData = async () => {
    try {
        const token = localStorage.getItem("token");
        const auth = useAuthStore();

        let custId = "";
        if (auth.isLoggedIn && auth.user !== null) {
            custId = auth.user.custID;
        }

        const response = await axios.get(ENDPOINTS.GET_ACTIVATES_CUSTID_PROID.replace("{custId}", custId).replace("{productId}", props.productId), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            activateLicenses.value = response.data;
        } else {
            // console.error("Failed to fetch activation data.");
            notify("Failed to fetch activation data.", "error");
        }
    } catch (error) {
        // console.error(error);
        notify("Failed to fetch activation data.", "error");
    }
};

// onMounted(fetchData);

onMounted(() => {
    const isExpired = checkTokenExpired();

    if (isExpired.value) {
        const auth = useAuthStore();
        auth.logout();

        // หาก Token หมดอายุ ให้ทำการ redirect ไปยังหน้า login
        notify("Your token has expired. You will be redirected to the login page...", "warning");
        router.push({ name: 'login-form' });
    } else {
        // หาก Token ยังไม่หมดอายุ ทำการดึงข้อมูล
        fetchData();
    }
});

watch(() => props.productId, () => {
    fetchData();
});
</script>

<style lang="scss" scoped>
/* .dx-list-item-content>div {
    padding: 5px;
    font-size: 15px;
} */

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>