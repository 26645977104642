import { ref } from "vue";
import { defineStore } from "pinia";
import { ENDPOINTS } from "../utils/constants";
import axios from "axios";

export const useAuthStore = defineStore("auth", () => {
  // const isLoggedIn  = ref(false);
  // const user = ref(null);

  const isLoggedIn = ref(localStorage.getItem("isLoggedIn ") === "true");
  // const user = ref(JSON.parse(localStorage.getItem("user")));
  const storedUser = localStorage.getItem("user");
  const user = ref(
    storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null
  );

  function updateAuthStatus(value) {
    isLoggedIn.value = value;
    localStorage.setItem("isLoggedIn ", value.toString());
  }

  function updateUser(value) {
    user.value = value;
    localStorage.setItem("user", JSON.stringify(value));
  }

 
  async function logIn(email, password) {
    try {
      // Send request to API endpoint
      const response = await axios.post(
        ENDPOINTS.GET_TOKEN,
        {
          custEmail: email,
          custPassword: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Extract the token from the response
        const tokenString = response.data;
        // Store the token in a secure place (e.g., local storage)
        localStorage.setItem("token", tokenString);
        // localStorage.setItem("email", email);

        // Call getUser to retrieve user data
        const userData = await getUser(email);

        // Update the authentication status and user data
        updateAuthStatus(true);
        if (userData !== null) {
          updateUser(userData);
        } else {
          // Handle error case when user data is null
          console.error("Failed to retrieve user data");
        }

        // Return success response
        return {
          isOk: true,
          data: user.value,
        };
      } else {
        // Authentication failed
        return {
          isOk: false,
          message: "Authentication failed",
        };
      }
    } catch (error) {
      // Error occurred during API request
      return {
        isOk: false,
        message: error.message,
      };
    }
  }

  function logout() {
    // Clear token from secure storage (e.g., local storage)
    localStorage.removeItem("token");
    // localStorage.removeItem("email");

    // Reset the authentication status and user data
    updateAuthStatus(false);
    updateUser(null);
  }

  async function getUser(custEmail) {
    try {
      // Retrieve user data from API based on the token
      // and set it in the user state
      const token = localStorage.getItem("token");
      const response = await axios.get(
        ENDPOINTS.GET_CUSTOMER_EMAIL.replace("{custEmail}", custEmail),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const userData = response.data;
        return userData;
      } else {
        // Handle error if user data retrieval fails
        console.error("Failed to retrieve user data");
        return null;
      }
    } catch (error) {
      // Error occurred during API request
      console.error("Failed to retrieve user data");
      return null;
    }
  }

  return {
    isLoggedIn,
    user,
    logIn,
    logout,
    getUser,
    updateAuthStatus,
    updateUser,
  };
});
