<template>
  <div>
    <h2 class="content-block">Home - หน้าหลัก</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings" v-if="summaryData">

        <DxTileView :items="summaryData" :base-item-height="110" :base-item-width="275" :item-margin="10"
          :onItemClick="handleItemClick">

          <template #item="{ data }">
            <div class="card">
              <div>
                <b>{{ getProductNameEng(data.productName) + " " + getProductEditions(data.productCode) }}</b> <br>
                <span>
                  <span class="quantity-label">License : {{ data.quantity }}</span><br>
                  <span class="remaining-label">Remaining : {{ data.qtyRemain }}</span>
                </span><br>

                <!-- <span :style="{ color: isDateExpired(data.expireDate) ? 'red' : 'inherit' }">Expiration Date : {{
                  formatDate(data.expireDate) }}</span> -->

                <span :style="expirationStyle(data.expireDate)">Expiration Date : {{ formatDate(data.expireDate)
                  }}</span>

              </div>
            </div>
          </template>

        </DxTileView>
      </div>

      <div class="center-container" v-else>
        <DxLoadIndicator />
      </div>
    </div>
  </div>

  <DxPopup v-model:visible="isPopupVisible" content-template="popup-content" :hide-on-outside-click="true"
    :show-title="true" title="Activated Computer Licenses" :height="600" :width="800" :resize-enabled="true"
    :drag-enabled="true" position="center" :show-close-button="true">

    <template #popup-content>
      <DxScrollView height="100%" width="100%">
        <LicenseDisplay :productId="productId" />
      </DxScrollView>
    </template>
  </DxPopup>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import notify from 'devextreme/ui/notify';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import { DxPopup } from 'devextreme-vue/popup';
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTileView from 'devextreme-vue/tile-view';

import axios from 'axios';
import { useAuthStore } from "../stores/useAuthStore";
import { ENDPOINTS } from "../utils/constants";
import checkTokenExpired from "../utils/checkTokenExpired";

import LicenseDisplay from "../components/license-display";

import { useRouter } from "vue-router";

import ThemeService from '../services/theme-service';

const router = useRouter();

const summaryData = ref(0);
const isPopupVisible = ref(false);

const productId = ref("");
const custId = ref("");

const currentTheme = computed(() => {
  return ThemeService.isLightMode() ? 'light' : 'dark';
});

const expirationStyle = (expireDate) => {
  return {
    color: isDateExpired(expireDate) ? `var(--color-expired-${currentTheme.value})` : 'inherit'
  };
};

const handleItemClick = (e) => {
  productId.value = e.itemData.productID;
  //  notify(e.itemData.productID + " is clicked", "success");
  // router.push({ name: "purchases-history" });
  isPopupVisible.value = true;
};

const getProductNameEng = (text) => {
  const start = text.lastIndexOf("(") + 1;
  const end = text.lastIndexOf(")");
  return text.substring(start, end);
}

const getProductEditions = (text) => {
  const parts = text.split("-");
  const extractedText = parts.length > 1 ? parts[1].trim() : text;
  return `(${extractedText})`;
}

const isDateExpired = (date) => {
  const currentDate = new Date();
  return new Date(date) < currentDate;
};

const formatDate = (date) => {
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // ตรวจสอบว่าปีเกิน 3000 หรือไม่
  const year = new Date(date).getFullYear();
  if (year >= 3000) {
    return "Lifetime Warranty";
  }

  return new Date(date).toLocaleDateString(undefined, options);
};

const fetchData = async () => {
  try {
    const token = localStorage.getItem("token");
    const auth = useAuthStore();

    // let custId = "";
    if (auth.isLoggedIn && auth.user !== null) {
      custId.value = auth.user.custID;
    }

    const response = await axios.get(ENDPOINTS.GET_SALEACTIVATION_SUMMARY.replace("{custId}", custId.value), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      summaryData.value = response.data;
    } else {
      // console.error("Failed to fetch home data.");
      notify("Failed to fetch home data.", "error");
    }
  } catch (error) {
    // console.error(error);
    notify("Failed to fetch home data.", "error");
  }
};

// onMounted(fetchData);

onMounted(() => {
  const isExpired = checkTokenExpired();

  if (isExpired.value) {
    const auth = useAuthStore();
    auth.logout();

    // หาก Token หมดอายุ ให้ทำการ redirect ไปยังหน้า login
    notify("Your token has expired. You will be redirected to the login page...", "warning");
    router.push({ name: 'login-form' });
  } else {
    // หาก Token ยังไม่หมดอายุ ทำการดึงข้อมูล
    fetchData();
  }
});

</script>
<!-- 
<style lang="scss" scoped>
.box-item {
  text-align: center;
  padding-top: 34px;
  font-size: 16px;
}

.orange {
  background: #f39e6c
}

.yellow {
  background: #f5e5a6
}

.green {
  background: #94d7c7
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.quantity-label {
  /* font-weight: bold; */
  margin-right: 10px;
  color: blue;
}

.remaining-label {
  /* font-weight: bold; */
  color: green;
}

.card {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
</style> -->


<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "../themes/generated/variables.base.scss";
  /* background-color: darken($base-bg, 5); */
  display: flex;
  height: 100%;
  width: 100%;
}
</style>