<template>
    <!-- docid : {{ props.docId }} -->
    <div>
        <div class="dx-card responsive-paddings" v-if="imageFile">
            <img :src="imageFile" alt="Document file" />
        </div>

        <div class="center-container" v-else>
            <DxLoadIndicator />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';
import notify from 'devextreme/ui/notify';
import DxLoadIndicator from 'devextreme-vue/load-indicator';

import axios from 'axios';

import { useAuthStore } from "../stores/useAuthStore";
import { ENDPOINTS } from "../utils/constants";
import checkTokenExpired from "../utils/checkTokenExpired";

import { useRouter } from 'vue-router';

const imageFile = ref('');

const props = defineProps({
    docId: {
        type: String,
        required: true,
    }
});

const router = useRouter();

const fetchData = async () => {
    // let docId = "13a549ba-5445-4121-9d99-32deea841c4f";
    // console.log("docId: ", props.docId);
    try {
        const token = localStorage.getItem("token");

        const response = await axios.get(ENDPOINTS.GET_DOCURL_DOCID.replace("{docId}", props.docId), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            // const imageData = `data:image/jpeg;base64,${response.data}`;
            // imageFile.value = imageData;

            // const blob = new Blob([response.data], { type: 'image/png' }); // สร้าง Blob จากข้อมูลไฟล์รูปภาพที่ได้รับ
            // imageFile.value = URL.createObjectURL(blob); // สร้าง URL จาก Blob และกำหนดค่าให้กับ imageData

            // URL
            imageFile.value = response.data;
        } else {
            // console.error("Failed to fetch document data.");
            notify("Failed to fetch document data.", "error");
        }
    } catch (error) {
        // console.error(error);
        notify("Failed to fetch document data.", "error");
    }
};

// onMounted(fetchData);

onMounted(() => {
    const isExpired = checkTokenExpired();

    if (isExpired.value) {
        const auth = useAuthStore();
        auth.logout();

        // หาก Token หมดอายุ ให้ทำการ redirect ไปยังหน้า login
        notify("Your token has expired. You will be redirected to the login page...", "warning");
        router.push({ name: 'login-form' });
    } else {
        // หาก Token ยังไม่หมดอายุ ทำการดึงข้อมูล
        fetchData();
    }
});

watch(() => props.docId, () => {
    fetchData();
});
</script>

<style lang="scss" scoped>
/* .dx-list-item-content>div {
    padding: 5px;
    font-size: 15px;
} */

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>